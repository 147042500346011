import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import "./propertyservices.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { registerPropertyServices } from "../../services/PropertyServices/RegisterPropertyServices";
import { fetchPropertyServices } from "../../services/PropertyServices/FetchPropertyServices";
import { updatePropertyServices } from "../../services/PropertyServices/UpdatePropertyServices";
import { deletePropertyServices } from "../../services/PropertyServices/DeletePropertyService";
import PropertyModalComponent from "../../components/PropertyModalComponent";

const PropertyServices = () => {
  const [selectedService, setSelectedService] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const openModal = (serviceName, isEditing, serviceData) => {
    setService(serviceName);
    setIsEditing(isEditing);
    setSelectedService(serviceData);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [serviceName, setService] = useState("");
  const [serviceProvider, setProvider] = useState("");
  const [providerEmail, setEmail] = useState("");
  const [providerPhone, setPhone] = useState("");
  const [contract, setContract] = useState("");
  const [contractDate, setContractDate] = useState(false);

    const strataData = JSON.parse(localStorage.getItem('strataData') || '{}');
    const { strata_plan_no: strataPlan } = strataData;

  const [propertyServices, setPropertyServices] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  const openDeleteConfirm = (service) => {
    setServiceToDelete({ strataPlan, serviceName: service.service_name });
    setShowDeleteConfirm(true);
  };

  const closeDeleteConfirm = () => {
    setShowDeleteConfirm(false);
  };

  const handleDelete = async () => {
    if (serviceToDelete) {
      await deletePropertyServices(strataPlan, serviceToDelete.serviceName);
      setRefreshData(!refreshData); // Flip the flag to refresh the data
    }
    closeDeleteConfirm();
  };

  // Fetching from server
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedServices = await fetchPropertyServices(strataPlan);
        setPropertyServices(fetchedServices);

        // Set the service name with the name of the first service
        if (fetchedServices && fetchedServices.length > 0) {
          setService(fetchedServices[0].service_name);
        }
      } catch (error) {
        console.error("Error fetching property services:", error);
      }
    };

    fetchData();
  }, [strataPlan, refreshData]);

  // Refreshing data after closing modal
  const handleSubmit = async (formData) => {
    try {
      let updatedService;
      const onSuccess = () => {
        setRefreshData(!refreshData); // Flip the flag
      };
      if (isEditing) {
        updatedService = await updatePropertyServices(formData, onSuccess);
      } else {
        updatedService = await registerPropertyServices(formData);
        if (updatedService && formData.strataPlan) {
          setIsEditing(true); // Set isEditing to true after successful registration
          const fetchedServices = await fetchPropertyServices(
            formData.strataPlan
          ); // Refresh the data
          setPropertyServices(fetchedServices); // Update the state with the refreshed data
          console.log("Fetched services:", fetchedServices);
        }
      }
      console.log("Updated service:", updatedService);
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <div className="strata-boss-bg-green" style={{ padding: "35px" }}>
      <h2 className="dashboard-heading center-heading">
        Property Services Summary List for #{strataPlan}
      </h2>
      <table className="center-table wideTable">
        <thead>
          <tr className="ps-table-row">
            <th className="table-header center-text">Service</th>
            <th className="table-header center-text">Provider</th>
            <th className="table-header center-text">Email</th>
            <th className="table-header center-text">Phone</th>
            <th className="table-header center-text">Contract</th>
            <th className="table-header center-text">Contract Date</th>
            <th className="table-header"></th>
            <th className="table-header"></th>
          </tr>
        </thead>
        <tbody>
          {propertyServices &&
            propertyServices.map((service) => (
              <tr key={service.id} className="table-row">
                <td className="ps-table-row">{service.service_name}</td>
                <td className="ps-table-row center-text">
                  {service.service_provider}
                </td>
                <td className="ps-table-row center-text">
                  {service.provider_email}
                </td>
                <td className="ps-table-row center-text">
                  {service.provider_phone_no}
                </td>
                <td className="ps-table-row center-text">
                  {service.contract ? "Y" : "N"}
                </td>
                <td className="ps-table-row center-text">
                  {service.contract_renewal_date
                    ? moment(
                        service.contract_renewal_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="ps-table-row center-text">
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ fontSize: "20px" }}
                    color="#272727"
                    onClick={() =>
                      openModal(service.service_name, true, service)
                    }
                  />
                </td>
                <td className="ps-table-row center-text">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{ fontSize: "28px" }}
                    color="#272727"
                    onClick={() => openDeleteConfirm(service)}
                  />
                </td>
              </tr>
            ))}
          <tr
            className="ps-table-row"
            style={{
              backgroundColor:
                (propertyServices?.length || 0) % 2 === 0 ? "white" : "#e8e8e8",
            }}
          >
            <td className="ps-table-row">-</td>
            <td className="ps-table-row">-</td>
            <td className="ps-table-row">-</td>
            <td className="ps-table-row">-</td>
            <td className="ps-table-row center-text">-</td>
            <td className="ps-table-row">-</td>
            <td className="ps-table-row center-text">
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ fontSize: "28px" }}
                onClick={() => openModal(null, false)}
              />
            </td>
            <td className="ps-table-row"></td>
          </tr>
        </tbody>
      </table>
      {modalIsOpen && (
        <PropertyModalComponent
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          strataPlan={strataPlan}
          serviceName={serviceName}
          setService={setService}
          serviceProvider={serviceProvider}
          setProvider={setProvider}
          providerEmail={providerEmail}
          setEmail={setEmail}
          providerPhone={providerPhone}
          setPhone={setPhone}
          contract={contract}
          setContract={setContract}
          contractDate={contractDate}
          setContractDate={setContractDate}
          isEditing={isEditing}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          // Pass other props here...
        />
      )}
      <Modal show={showDeleteConfirm} onHide={closeDeleteConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm delete of Service {serviceToDelete?.serviceName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PropertyServices;
