import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// assets
import stratabosslogo from "../../assets/Logo/logo_square.png";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, error, loginWithRedirect, logout, loading } =
    useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    if (!loading) {
      console.log({ isAuthenticated, error, user });
      if (isAuthenticated) {
        alert("Login successful!");
      }
      if (error) {
        alert("Login failed. Please try again.");
      }
    }
  }, [isAuthenticated, error, user]);

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          <NavbarBrand className="mb-3 app-logo">
            <img
              src={stratabosslogo}
              alt="StrataBoss logo"
              height="60"
              width="60"
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse className="justify-content-between" isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>
              {/* {isAuthenticated && ( */}
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/strata-dashboard"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Dashboard
                </NavLink>
              </NavItem>
              {/* )} */}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/register-strata"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Register Strata
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin login-button-color"
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}

            {isAuthenticated && (
              <ProfileDropdown
                user={user}
                logoutWithRedirect={logoutWithRedirect}
              />
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

const ProfileDropdown = ({ user, logoutWithRedirect }) => {
  return (
    <Nav
      className="d-md-none justify-content-between"
      navbar
      style={{ minHeight: 170 }}
    >
      <NavItem>
        <span className="user-info">
          <img
            src={user.picture}
            alt="Profile"
            className="nav-user-profile d-inline-block rounded-circle mr-3"
            width="50"
          />
          <h6 className="d-inline-block">{user.name}</h6>
        </span>
      </NavItem>
      <NavItem>
        <FontAwesomeIcon icon="user" className="mr-3" />
        <RouterNavLink to="/profile" activeClassName="router-link-exact-active">
          Profile
        </RouterNavLink>
      </NavItem>
      <NavItem>
        <FontAwesomeIcon icon="power-off" className="mr-3" />
        <RouterNavLink
          to="#"
          id="qsLogoutBtn"
          onClick={() => logoutWithRedirect()}
        >
          Log out
        </RouterNavLink>
      </NavItem>
    </Nav>
  );
};
