import React, { useEffect } from "react";
import Modal from "react-modal";
import Select from "react-select";

Modal.setAppElement("#root");

const OCModalComponent = ({
  modalIsOpen,
  closeModal,
  handleSubmit,
  currentLot,
  unitNo,
  setUnitNo,
  ownerName,
  setOwnerName,
  email,
  setEmail,
  phone,
  setPhone,
  ocRole,
  setOcRole,
  ownerOccupied,
  setOwnerOccupied,
  invoiceFrequency,
  setInvoiceFrequency,
  strataPlan,
  isEditing,
  selectedOCMember,
}) => {
  const ocRoleOptions = [
    { value: "chairperson", label: "Chairperson" },
    { value: "ocCommittee", label: "OC Committee" },
    { value: "ocMember", label: "OC Member" },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (modalIsOpen) {
      if (isEditing && selectedOCMember) {
        setUnitNo(selectedOCMember.unit_no);
        setOwnerName(selectedOCMember.oc_member_name);
        setEmail(selectedOCMember.oc_member_email);
        setPhone(selectedOCMember.oc_member_phone_no);
        setOcRole(
          ocRoleOptions.find(
            (option) => option.label === selectedOCMember.oc_role
          )
        );
        setOwnerOccupied(selectedOCMember.owner_occupied);
        setInvoiceFrequency(selectedOCMember.invoice_frequency);
      } else {
        setUnitNo("");
        setOwnerName("");
        setEmail("");
        setPhone("");
        setOcRole(null);
        setOwnerOccupied(false);
        setInvoiceFrequency("");
      }
    }
  }, [modalIsOpen, isEditing, selectedOCMember]);

  const submitForm = (event) => {
    event.preventDefault();

    console.log("OC Role:", ocRole);

    const formData = {
      strataPlan,
      currentLot,
      ownerName,
      email,
      phone,
      ocRole,
      ownerOccupied,
      invoiceFrequency,
      unitNo,
    };

    handleSubmit(formData);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Edit Form"
      className="modal-container"
    >
      <div className="modal-content form-container">
        <h6>
          {isEditing ? "Editing" : "Adding"} OC Member details for Lot #{" "}
          {currentLot}
        </h6>
        <form onSubmit={submitForm} className="form-container">
          <input
            type="text"
            name="unitNo"
            placeholder="Unit No"
            className="input-height"
            value={unitNo}
            onChange={(e) => setUnitNo(e.target.value)}
            required
          />
          <input
            type="text"
            name="ownerName"
            placeholder="Owner Fullname"
            className="input-height"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="input-height"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone No"
            className="input-height"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <div className="flex-row">
            <label className="label-input">OC Role</label>
            <Select
              options={ocRoleOptions}
              name="ocRole"
              className="select-width"
              value={ocRole}
              onChange={(option) => setOcRole(option)}
              required
            />
          </div>
          <label
            className="label-input checkbox-label"
            style={{ paddingTop: "20px" }}
          >
            Owner Occupied&nbsp;&nbsp;&nbsp;
            <input
              type="checkbox"
              name="ownerOccupied"
              className="checkbox-input"
              checked={ownerOccupied}
              onChange={(e) => setOwnerOccupied(e.target.checked)}
            />
          </label>
          <label className="label-input">
            Invoice Frequency&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              name="invoiceFrequency"
              value="Annual"
              className="radio-input"
              checked={invoiceFrequency === "Annual"}
              onChange={(e) => setInvoiceFrequency(e.target.value)}
              required
            />{" "}
            &nbsp;Annual&nbsp;&nbsp;
            <input
              type="radio"
              name="invoiceFrequency"
              value="Quarterly"
              className="radio-input"
              checked={invoiceFrequency === "Quarterly"}
              onChange={(e) => setInvoiceFrequency(e.target.value)}
            />{" "}
            &nbsp;Quarterly&nbsp;&nbsp;
          </label>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default OCModalComponent;
