export const updatePropertyServices = async (formData, onSuccess) => {
  // Destructure the data from formData
  const {
    strataPlan,
    serviceName,
    serviceProvider,
    providerEmail,
    providerPhone,
    contract,
    contractDate,
  } = formData;

  try {
    const response = await fetch(
      "https://model-seasnail-56.hasura.app/v1/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret":
            "0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G",
        },
        body: JSON.stringify({
          query: `
            mutation UpdatePropertyServices($strataPlan: String!, $serviceName: String!, $serviceProvider: String!, $providerEmail: String!, $providerPhone: String!, $contract: Boolean!, $contractDate: date) {
              update_property_services(where: {serv_strata_plan_no: {_eq: $strataPlan}, service_name: {_eq: $serviceName}}, _set: {service_provider: $serviceProvider, provider_email: $providerEmail, provider_phone_no: $providerPhone, contract: $contract, contract_renewal_date: $contractDate}) {
                affected_rows
              }
            }
          `,
          variables: {
            strataPlan,
            serviceName,
            serviceProvider,
            providerEmail,
            providerPhone,
            contract,
            contractDate,
          },
        }),
      }
    );

    const data = await response.json();

    if (data.errors) {
      console.error(data.errors);
      const errorMessage = data.errors
        .map(
          (error) =>
            `Message: ${error.message}, Code: ${error.extensions?.code}`
        )
        .join("\n");
      alert(
        `Failed to update property service. Error details:\n${errorMessage}`
      );
      return null;
    } else if (data.data.update_property_services.affected_rows > 0) {
      alert(
        `Successfully updated property service ${serviceName} for ${strataPlan}`
      );
      onSuccess(); // Flip the flag
      return data.data.update_property_services.affected_rows;
    } else {
      alert(
        `No property service was updated. Please check the provided details.`
      );
      return 0;
    }
  } catch (error) {
    console.error("Error in updatePropertyServices:", error);
    alert(
      `An error occurred while updating the property service. Please try again.`
    );
    return null;
  }
};
