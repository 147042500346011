import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
// components
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
// utils & services
import RegisterStrata from "./services/RegisterStrata";
import history from "./utils/history";
// styles
import { Container } from "reactstrap";
import "./App.css";
// views
import Home from "./views/Home";
import Profile from "./views/Profile";
import EditProfile from "./views/EditProfile";
import StrataDashboard from "./views/StrataDashboard";
import PropertyDetails from "./views/PropertyDetails";
import OCMembers from "./views/OCMembers";
import PropertyServices from "./views/PropertyServices";
import GenerateInvoices from "./views/GenerateInvoices";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import IssueRegister from "./views/IssueRegister";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Auth0Provider
      domain={"dev-jcpvp047vaxl37c6.au.auth0.com"}
      clientId={"E5md2EVTJYF7dYwvJ5GEWzV3D262YrBc"}
      redirectUri={window.location.origin}
    >
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5 mb-5">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/edit-profile" component={EditProfile} />
              <Route path="/strata-dashboard" component={StrataDashboard} />
              <Route path="/issue-register" component={IssueRegister} />
              <Route path="/register-strata" component={RegisterStrata} />
              <Route path="/property-details" component={PropertyDetails} />
              <Route path="/oc-members" component={OCMembers} />
              <Route path="/property-services" component={PropertyServices} />
              <Route path="/generate-invoices" component={GenerateInvoices} />
            </Switch>
          </Container>
          <Footer />
        </div>
      </Router>
    </Auth0Provider>
  );
};

export default App;
