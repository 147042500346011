import React from "react";
import "./generateinvoices.css";

const GenerateInvoices = () => {
  const strataData = JSON.parse(localStorage.getItem("strataData") || "{}");
  const { strataPlan, annualBudget, numLots } = strataData;
  const currentDate = new Date();

  let startYear = currentDate.getFullYear();
  let endYear = currentDate.getFullYear();

  if (currentDate.getMonth() < 5) {
    startYear--;
  } else {
    endYear++;
  }

  const financialYearStart = `1 May ${startYear}`;
  const financialYearEnd = `30 Apr ${endYear}`;

  return (
    <div className="strata-boss-bg-green w-100" style={{ padding: "40px" }}>
      <h2 className="dashboard-heading">
        Generate Invoices for Strata Plan #{strataPlan}
      </h2>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className="dashboard-tile"
          style={{
            width: "100%",
            marginBottom: "20px",
            height: "160px",
            paddingTop: "10px",
            overflow: "auto",
          }}
        >
          <h5 className="property-details-tile-heading">Financial Overview</h5>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ paddingLeft: "20px", paddingRight: "10px" }}>
              Current Financial Year:
            </p>
            <input
              type="text"
              value={financialYearStart}
              readOnly
              style={{ marginTop: "-15px", height: "30px" }}
              size={10}
            />
            <p style={{ paddingLeft: "10px", paddingRight: "10px" }}>to</p>
            <input
              type="text"
              value={financialYearEnd}
              readOnly
              style={{ marginTop: "-15px", height: "30px" }}
              size={10}
            />
            <p style={{ paddingLeft: "80px", paddingRight: "10px" }}>
              Annual Fees/Lot:
            </p>
            <input
              type="text"
              value={`$${new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
              }).format(annualBudget / numLots)}`}
              readOnly
              size="9"
              style={{ textAlign: "right", marginTop: "-15px", height: "30px" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="generate-invoices-button"
              style={{
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "14px",
                margin: "4px 2px",
                cursor: "pointer",
              }}
            >
              Generate Invoices
            </button>
          </div>
        </div>
        <div
          className="dashboard-tile"
          style={{
            width: "100%",
            marginBottom: "20px",
            height: "320px",
            paddingTop: "10px",
            overflow: "auto",
          }}
        >
          <h5 className="property-details-tile-heading">
            Invoice Summary by Lot
          </h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Lot #</th>
                <th>Invoice Frequency</th>
                <th>Date Invoiced</th>
                <th>View Invoices</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: numLots }, (_, i) => (
                <tr key={i}>
                  <td style={{ textAlign: "center" }}>{i + 1}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoices;
