import React from "react";
import { Table } from "react-bootstrap";

export default function IssueRegister() {
  const data = [
    {
      id: 1,
      defect: "Broken link on homepage",
      dateDetected: "2023-04-01",
      reportedBy: "Jane Doe",
      description:
        'The link to the "About Us" page on the homepage is not working.',
      quoteValue: 500,
      severity: "High",
      status: "Open",
      nextSteps: "Investigate and fix the broken link.",
      supportingDocs: "example.com/docs",
    },
    {
      id: 2,
      defect: "Typo in product description",
      dateDetected: "2023-03-15",
      reportedBy: "John Smith",
      description:
        'There is a spelling error in the product description for the "Widget XL" item.',
      quoteValue: 200,
      severity: "Low",
      status: "Closed",
      nextSteps: "Correct the typo and update the product description.",
      supportingDocs: "example.com/docs",
    },
    {
      id: 3,
      defect: "Slow loading time on checkout page",
      dateDetected: "2023-02-28",
      reportedBy: "Sarah Lee",
      description:
        "The checkout page is taking longer than 5 seconds to load, which is impacting the user experience.",
      quoteValue: 1000,
      severity: "Medium",
      status: "Open",
      nextSteps: "Optimize the page's assets and code to improve loading time.",
      supportingDocs: "example.com/docs",
    },
    {
      id: 4,
      defect: "Incorrect pricing on sale items",
      dateDetected: "2023-01-20",
      reportedBy: "Michael Johnson",
      description:
        "The prices displayed for the sale items do not match the actual discounted prices.",
      quoteValue: 800,
      severity: "High",
      status: "Open",
      nextSteps:
        "Investigate the pricing issue and update the sale prices accordingly.",
      supportingDocs: "example.com/docs",
    },
    {
      id: 5,
      defect: "Broken image on product page",
      dateDetected: "2023-01-10",
      reportedBy: "Emily Davis",
      description:
        'The main product image for the "Gadget Pro" item is not displaying correctly.',
      quoteValue: 300,
      severity: "Medium",
      status: "Closed",
      nextSteps: "Replace the broken image with a working one.",
      supportingDocs: "example.com/docs",
    },
  ];

  return (
    <div className="p-4 strata-boss-bg-green">
      <Table striped bordered hover className="strata-boss-bg-white">
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th>Defect</th>
            <th>Date Detected</th>
            <th>Reported by</th>
            <th>Description</th>
            <th>Quote Value</th>
            <th>Severity</th>
            <th>Status</th>
            <th>Next Steps</th>
            <th>Supporting Docs</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.defect}</td>
              <td>{item.dateDetected}</td>
              <td>{item.reportedBy}</td>
              <td>{item.description}</td>
              <td>${item.quoteValue}</td>
              <td>{item.severity}</td>
              <td>{item.status}</td>
              <td>{item.nextSteps}</td>
              <td>
                <a href={item.supportingDocs}>Link</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
