export const registerOCMember = async (formData) => {
  // Destructure the data from formData
  const {
    strataPlan,
    currentLot,
    ownerName,
    email,
    phone,
    ocRole,
    ownerOccupied,
    invoiceFrequency,
    unitNo,
  } = formData;

  const { label: role } = ocRole;

  try {
    const response = await fetch(
      "https://model-seasnail-56.hasura.app/v1/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret":
            "0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G",
        },
        body: JSON.stringify({
          query: `
            mutation RegisterOcMember($strataPlan: String!, $currentLot: Int!, $ownerName: String!, $email: String!, $phone: String!, $role: String!, $ownerOccupied: Boolean!, $invoiceFrequency: String!, $unitNo: String!) {
              insert_oc_lots(objects: {oc_strata_plan_no: $strataPlan, lot_no: $currentLot, oc_member_name: $ownerName, oc_member_email: $email, oc_member_phone_no: $phone, oc_role: $role, owner_occupied: $ownerOccupied, invoice_frequency: $invoiceFrequency, unit_no: $unitNo}) {
                affected_rows
              }
            }
          `,
          variables: {
            strataPlan,
            currentLot: parseInt(currentLot),
            ownerName,
            email,
            phone,
            role,
            ownerOccupied,
            invoiceFrequency,
            unitNo,
          },
        }),
      }
    );

    const data = await response.json();

    if (data.errors) {
      console.error(data.errors);
      const errorMessage = data.errors
        .map(
          (error) =>
            `Message: ${error.message}, Code: ${error.extensions?.code}`
        )
        .join("\n");
      alert(`Failed to register OC member. Error details:\n${errorMessage}`);
    } else {
      alert(`Successfully registered OC member for ${strataPlan}`);
    }
  } catch (error) {
    console.error(error);
  }
};
