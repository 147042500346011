import React from "react";
import { Container, Row, Col } from "reactstrap";
import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Form, FormControl } from "react-bootstrap";

export const EditProfileComponent = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>

      {/* <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row> */}
      <Row>
        <Form>
          <Form.Group controlId="formFullName">
            <Form.Label>Full Name</Form.Label>
            <FormControl type="text" defaultValue={user.name} />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <FormControl type="email" defaultValue={user.email} />
          </Form.Group>
          <Form.Group controlId="formPhoneNo">
            <Form.Label>Phone No</Form.Label>
            <FormControl type="tel" />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <FormControl type="password" />
          </Form.Group>
        </Form>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(EditProfileComponent, {
  onRedirecting: () => <Loading />,
});
