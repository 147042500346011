import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChevronRight,
  faWallet,
  faUserPlus,
  faInfo,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

export const ProfileComponent = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
          <Link to="/edit-profile" className="edit-profile-button text-center">
            Edit Profile
          </Link>
        </Col>
      </Row>
      <div className="horizontal-line" />
      <Row className="settings-row">
        <Col xs={2}>
          <div className="icon-circle" style={{ backgroundColor: "#9902341A" }}>
            <FontAwesomeIcon icon={faCog} className="settings-icon" />
          </div>
        </Col>
        <Col xs={8}>
          <h4>Settings</h4>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div className="icon-circle" style={{ backgroundColor: "#b9b9b91A" }}>
            <Button
              variant="link"
              className="icon-circle"
              style={{ backgroundColor: "#b9b9b91A" }}
            >
              <FontAwesomeIcon icon={faChevronRight} className="arrow-icon" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="settings-row">
        <Col xs={2}>
          <div className="icon-circle" style={{ backgroundColor: "#9902341A" }}>
            <FontAwesomeIcon icon={faWallet} className="settings-icon" />
          </div>
        </Col>
        <Col xs={8}>
          <h4>Billing Details</h4>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div className="icon-circle" style={{ backgroundColor: "#b9b9b91A" }}>
            <Button
              variant="link"
              className="icon-circle"
              style={{ backgroundColor: "#b9b9b91A" }}
            >
              <FontAwesomeIcon icon={faChevronRight} className="arrow-icon" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="settings-row">
        <Col xs={2}>
          <div className="icon-circle" style={{ backgroundColor: "#9902341A" }}>
            <FontAwesomeIcon icon={faUserPlus} className="settings-icon" />
          </div>
        </Col>
        <Col xs={8}>
          <h4>User Management</h4>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div className="icon-circle" style={{ backgroundColor: "#b9b9b91A" }}>
            <Button
              variant="link"
              className="icon-circle"
              style={{ backgroundColor: "#b9b9b91A" }}
            >
              <FontAwesomeIcon icon={faChevronRight} className="arrow-icon" />
            </Button>
          </div>
        </Col>
      </Row>
      <div className="horizontal-line" />
      <Row className="settings-row">
        <Col xs={2}>
          <div className="icon-circle" style={{ backgroundColor: "#9902341A" }}>
            <FontAwesomeIcon icon={faInfo} className="settings-icon" />
          </div>
        </Col>
        <Col xs={8}>
          <h4>Information</h4>
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <div className="icon-circle" style={{ backgroundColor: "#b9b9b91A" }}>
            <Button
              variant="link"
              className="icon-circle"
              style={{ backgroundColor: "#b9b9b91A" }}
            >
              <FontAwesomeIcon icon={faChevronRight} className="arrow-icon" />
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="settings-row">
        <Col xs={2}>
          <div className="icon-circle" style={{ backgroundColor: "#9902341A" }}>
            <FontAwesomeIcon icon={faArrowRight} className="settings-icon" />
          </div>
        </Col>
        <Col xs={8}>
          <h4 style={{ color: "red" }}>Logout</h4>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
