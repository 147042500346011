// Extract the fetching logic into a separate function
const fetchOCMemberDetails = async (lotNumber, strataPlanNo) => {
  try {
    const response = await fetch(
      "https://model-seasnail-56.hasura.app/v1/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret":
            "0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G",
        },
        body: JSON.stringify({
          query: `
  query FetchOcMembers($lotNumber: Int!, $strataPlanNo: String!) {
    oc_lots(where: {lot_no: {_eq: $lotNumber}, oc_strata_plan_no: {_eq: $strataPlanNo}}) {
      lot_no
      oc_member_name
      oc_member_email
      oc_member_phone_no
      oc_role
      owner_occupied
      invoice_frequency
      unit_no
    }
  }
`,
          variables: {
            lotNumber,
            strataPlanNo,
          },
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (
      !data ||
      !data.data ||
      !data.data.oc_lots ||
      data.data.oc_lots.length === 0
    ) {
      return null;
    }

    return data.data.oc_lots[0];
  } catch (error) {
    console.error("Error fetching OC member details:", error);
    return null;
  }
};
export { fetchOCMemberDetails };
