import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

const PropertyDetails = () => {
  const [strataData, setStrataData] = useState({});
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const storedStrataData = localStorage.getItem('strataData');
    if (storedStrataData) {
      setStrataData(JSON.parse(storedStrataData));
    }
  }, []);

  const { strata_plan_no, address, building_type, annual_budget, no_of_lots } = strataData;

  return (
    <div style={{ backgroundColor: '#32aab8', padding: '40px', width: '100%' }}>
    <h2  className="dashboard-heading" style={{ color: '#F7F6F1', textAlign: 'left', marginBottom: '25px' }}>Property Details for Strata Plan #{strata_plan_no}</h2>
    <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'space-between' }}>
    <div className="dashboard-tile" style={{ width: isSmallScreen ? '100%' : '48%', marginBottom: '20px', height: '250px', paddingTop: '10px', overflow: 'auto' }}>
    <h5 className="property-details-tile-heading">Property Details</h5>
    <p className="property-details-text">Plan #{strata_plan_no}</p>
  <div style={{ display: 'flex', alignItems: 'center' }}>
  <FontAwesomeIcon icon={faAddressCard} size="4x" color="#32aab8" style={{ paddingLeft: '25px' }} />
    <div>
    {address && address.map((line, index) => (
    <p key={index} className="property-details-text">{line}</p>
  ))}
    </div>
  </div>
  <p className="property-details-text">{building_type}</p>
  <p className="property-details-text">{no_of_lots} lots</p>
  </div>

  <div className="dashboard-tile" style={{ width: isSmallScreen ? '100%' : '48%', marginBottom: '20px', height: '250px', paddingTop: '10px', overflow: 'auto' }}>
  <h5 className="property-details-tile-heading">Financials</h5>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: isSmallScreen ? 'center' : 'flex-start' }}>
    <p className="financials-text">Strata Annual Budget:</p>
    <div style={{ paddingRight: '25px' }}>
      <input type="text" value={`$${annual_budget}`} readOnly size="9" style={{ textAlign: 'right', height: '30px' }}/>
    </div>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: isSmallScreen ? 'center' : 'flex-start' }}>
    <p className="financials-text">Annual Fees per lot:</p>
    <div style={{ paddingRight: '25px' }}>
      <input type="text" value={`$${annual_budget / no_of_lots}`} readOnly size="9" style={{ textAlign: 'right', height: '30px' }}/>
    </div>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: isSmallScreen ? 'center' : 'flex-start' }}>
    <p className="financials-text">Quarterly Strata Fees:</p>
    <div style={{ paddingRight: '25px' }}>
      <input type="text" value={`$${(annual_budget / no_of_lots) / 4}`}  readOnly size="8" style={{ textAlign: 'right', height: '30px' }}/>
    </div>
  </div>
</div>
</div>
<div className="dashboard-tile" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '250px', paddingTop: '10px', marginBottom: '20px', overflow: 'auto' }}>
    <h5 className="property-details-tile-heading">Strata Administration</h5>
    <p className="strata-admin-text">Financial Year: 1 May '23 - 31 Apr '24</p>
    <table>
    <thead>
      <tr>
        <th className="tile-table-row">FY Quarter #</th>
        <th className="tile-table-row">Date Range</th>
        <th className="tile-table-row">Invoice Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="tile-table-row">Q #1</td>
        <td className="tile-table-row">1 May '23 - 31 Jul '23</td>
        <td className="tile-table-row"><FontAwesomeIcon icon={faCircleCheck} color="green" /></td>
      </tr>
      <tr>
        <td className="tile-table-row">Q #2</td>
        <td className="tile-table-row">1 Aug '23 - 31 Oct '23</td>
        <td className="tile-table-row"><FontAwesomeIcon icon={faCircleCheck} color="green" /></td>
      </tr>
      <tr>
        <td className="tile-table-row">Q #3</td>
        <td className="tile-table-row">1 Nov '23 - 31 Jan '24</td>
        <td className="tile-table-row"><FontAwesomeIcon icon={faCircleXmark} color="red"></FontAwesomeIcon></td>
      </tr>
      <tr>
        <td className="tile-table-row">Q #4</td>
        <td className="tile-table-row">1 Feb '24 - 30 Apr '24</td>
        <td className="tile-table-row"><FontAwesomeIcon icon={faCircleXmark} color="red"></FontAwesomeIcon></td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
  );
};
export default PropertyDetails