import React , { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { registerPropertyServices } from '../../services/PropertyServices/RegisterPropertyServices';

export const RegisterStrata = () => {
  const [address, setAddress] = useState(["Address Line 1", "Address Line 2", "Suburb, State, Postcode, Country"]);
  const [strataPlan, setStrataPlan] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [propertyServices, setPropertyServices] = useState([]);
  const [annualBudget, setAnnualBudget] = useState("");
  const [numLots, setNumLots] = useState("");
  const { user } = useAuth0();

  const history = useHistory();
  
  const handleFocus = (e) => {
    if (e.target.value === "Address Line 1\nAddress Line 2\nSuburb, State, Postcode, Country") {
      setAddress([]);
    }
  };
  
  const handleBlur = (e) => {
    if (e.target.value === "") {
      setAddress(["Address Line 1", "Address Line 2", "Suburb, State, Postcode, Country"]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Create newServices array
    const newServices = propertyServices.map(serviceName => ({
      serviceName: serviceName,
      serviceProvider: '',
      providerEmail: '',
      providerPhone: '',
      contract: false,
      contractDate: null,
      strataPlan: strataPlan,
    }));
    
    try {
      const response = await fetch('https://model-seasnail-56.hasura.app/v1/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-hasura-admin-secret': '0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G',
        },
        body: JSON.stringify({
          query: `
            mutation RegisterStrata($strataPlan: String!, $address: [String!]!, $buildingType: String!, $propertyServices: [String!]!, $annualBudget: numeric!, $numLots: Int!, $email: String!) {
              insert_property_details(objects: {strata_plan_no: $strataPlan, address: $address, building_type: $buildingType, property_services: $propertyServices, annual_budget: $annualBudget, no_of_lots: $numLots, user_email: $email}) {
                affected_rows
              }
            }
          `,
          variables: { strataPlan, address, buildingType, propertyServices, annualBudget: parseFloat(annualBudget), numLots: parseInt(numLots), email: user.email },
        }),
      });
  
      const data = await response.json();
  
      if (data.errors) {
        console.error(data.errors);
        const errorMessage = data.errors.map((error) => `Message: ${error.message}, Code: ${error.extensions?.code}`).join('\n');
        alert(`Failed to register Strata. Error details:\n${errorMessage}`);
      } else {
        // Register each service
        for (const service of newServices) {
          const serviceResponse = await registerPropertyServices(service);
          if (!serviceResponse) {
            throw new Error(`Failed to register service: ${service.serviceName}`);
          }
        }
        alert(`Successfully registered Strata for ${strataPlan}`);
        localStorage.setItem('strataData', JSON.stringify({ strataPlan, address, buildingType, propertyServices, annualBudget, numLots }));
        history.push('/strata-dashboard', { strataPlan, address, buildingType, propertyServices, annualBudget, numLots });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <div>
  <Container style={{ backgroundColor: '#32aab8', padding: '35px' }}>
   <Container style={{ borderRadius: '50px', backgroundColor: '#f7f7f7', height: '100vh' }}>
     <Container>
      <Row>
      <Col className="text-center">
      <h4 className="mb-5" style={{ color: '#272727', paddingTop: '30px' }}>Register your Strata Property</h4>    
      </Col>
      </Row>
    </Container>
 <Container>
    <Form onSubmit={handleSubmit}>
      <Row>
       <Col md={6}>
            <Form.Group controlId="formStrataPlan" className="mb-5">
            <Form.Control type="text" placeholder="Strata Plan #" onChange={(e) => setStrataPlan(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formPropertyAddress" className="mb-5">
             <Form.Control as="textarea" rows={3} value={address.join('\n')} onFocus={handleFocus} onBlur={handleBlur} onChange={(e) => setAddress(e.target.value.split('\n'))} />
             </Form.Group>
            <Form.Group controlId="formBuildingType" className="mb-5">
             <Form.Label style={{ paddingRight: '10px' }}>Building type</Form.Label>
            <Form.Select style={{ height: 'calc(1.25em + .5rem + 2px + 5px)' }} defaultValue="Select Building Type" onChange={(e) => setBuildingType(e.target.value)} >
        <option disabled>Select Building Type</option>
        <option>Stand-alone</option>
        <option>Semi-detached</option>
        <option>Apartment building - Small</option>
        <option>Apartment building - Medium</option>
        <option>Apartment building - Large</option>
      </Form.Select>
    </Form.Group>
    <Form.Group controlId="formPropertyServices" className="mb-5">
    <Col md={10} className="d-flex align-items-center justify-content-md-end">
      <Form.Label style={{ paddingRight: '10px' }}>Property Services</Form.Label>
      <Form.Select multiple onChange={(e) => setPropertyServices(Array.from(e.target.selectedOptions, option => option.value))}>
        <option>Insurance</option>
        <option>Caretaking</option>
        <option>Strata Manager</option>
        <option>Waste Management</option>
        <option>Fire Services</option>
        <option>Essential Services</option>
        <option>Lifts</option>
        <option>Recreational Facilities</option>
        <option>Other Services</option>
      </Form.Select>
      </Col>
    </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="formAnnualBudget" className="mb-5">
  <Form.Control type="number" placeholder="Annual Budget AUD$" step="0.01" min="0" onChange={(e) => setAnnualBudget(e.target.value)} />
</Form.Group>
            <Form.Group controlId="formNumLots" className="mb-5">
              <Form.Control type="number" placeholder="# of lots" min="0" max="9999" onChange={(e) => setNumLots(e.target.value)} />
            </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="auto">
        <Button className="register-button" type="submit">Register your Strata Property</Button>
        </Col>
      </Row>
      </Form>
    </Container>
    </Container>
    </Container>
    </div>
  );
};

export default withAuthenticationRequired(RegisterStrata, {
  onRedirecting: () => <div>Loading...</div>,
});