import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import OCModalComponent from "../../components/OCModalComponent";
import "./ocmembers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { registerOCMember } from "../../services/OCMemberServices/RegisterOCMember";
import { fetchOCMemberDetails } from "../../services/OCMemberServices/FetchOCMemberDetails";
import { updateOCMember } from "../../services/OCMemberServices/UpdateOCMember";

const OCMembers = () => {
  const [currentLot, setCurrentLot] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOCMember, setSelectedOCMember] = useState(null);

  useEffect(() => {}, [selectedOCMember]);

  const openModal = (lotNumber, isEditing) => {
    setCurrentLot(lotNumber);
    setIsEditing(isEditing);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const refreshData = async () => {
    await fetchOCMemberDetails();
  };

  const [unitNo, setUnitNo] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ocRole, setOcRole] = useState("");
  const [ownerOccupied, setOwnerOccupied] = useState(false);
  const [invoiceFrequency, setInvoiceFrequency] = useState("");

  const handleSubmit = async (formData) => {
    try {
      let updatedLot;
      if (isEditing) {
        updatedLot = await updateOCMember(formData);
      } else {
        updatedLot = await registerOCMember(formData);
      }

      setLots((prevLots) => {
        return prevLots.map((lot) =>
          lot.unit === formData.unitNo ? updatedLot : lot
        );
      });
      setModalIsOpen(false);
      await fetchOcMembers(); // refresh the data
    } catch (error) {
      console.error(error);
    }
  };

    const strataData = JSON.parse(localStorage.getItem('strataData') || '{}');
    let { strata_plan_no: strataPlan, no_of_lots: numLots } = strataData;

  const [lots, setLots] = useState([]);

  const fetchOcMembers = useCallback(async () => {
    const newLots = [];
    const newErrors = [];

    for (let i = 0; i < numLots; i++) {
      const lotNumber = i + 1;
      try {
        const ocMember = await fetchOCMemberDetails(lotNumber, strataPlan);
        if (ocMember) {
          newLots.push({
            ...ocMember,
            unit: lotNumber,
          });
        } else {
          newLots.push({
            unit_no: null,
            oc_member_name: null,
            oc_member_email: null,
            oc_member_phone_no: null,
            oc_role: null,
            owner_occupied: null,
            invoice_frequency: null,
            lot_no: lotNumber,
          });
        }
      } catch (error) {
        console.error(`Failed to fetch details for lot ${lotNumber}:`, error);
        newErrors.push(
          `Failed to fetch details for lot ${lotNumber}: ${error.message}`
        );
      }
    }

    setLots(newLots);
  }, [numLots, strataPlan]); // dependencies of the useCallback hook

  useEffect(() => {
    fetchOcMembers();
  }, [fetchOcMembers]); // 'fetchOcMembers' is now a dependency

  //const ocRoleLabel = ocRoleOptions.find(option => option.value === lot.oc_role)?.label || '-';

  return (
    <div className="strata-boss-bg-green" style={{ padding: "35px" }}>
      <h2 className="dashboard-heading center-heading">
        Strata Plan #{strataPlan} OC Members Summary List
      </h2>
      <table className="center-table wideTable">
        <thead>
          <tr className="oc-table-row">
            <th className="table-header">Lot #</th>
            <th className="table-header">Unit #</th>
            <th className="table-header">Owner Name</th>
            <th className="table-header">Email</th>
            <th className="table-header">Phone No</th>
            <th className="table-header">OC Role</th>
            <th className="table-header">Owner-occupied</th>
            <th className="table-header">Invoice Frequency</th>
            <th className="table-header"></th>
          </tr>
        </thead>
        <tbody>
          {lots.map((lot, index) => {
            if (lot && lot.unit_no !== null) {
              return (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#e8e8e8",
                  }}
                >
                  <td className="oc-table-row center-text">{lot.lot_no}</td>
                  <td className="oc-table-row center-text">{lot.unit_no}</td>
                  <td className="oc-table-row">{lot.oc_member_name}</td>
                  <td className="oc-table-row">{lot.oc_member_email}</td>
                  <td className="oc-table-row">
                    {String(lot.oc_member_phone_no)}
                  </td>
                  <td className="oc-table-row">{lot.oc_role}</td>
                  <td className="oc-table-row center-text">
                    {lot.owner_occupied ? "Y" : "N"}
                  </td>
                  <td className="oc-table-row">{lot.invoice_frequency}</td>
                  <td className="oc-table-row center-heading">
                    <FontAwesomeIcon
                      icon={faPen}
                      size="lg"
                      color="#272727"
                      onClick={() => {
                        setSelectedOCMember(lot);
                        openModal(index + 1, true);
                      }}
                    />
                  </td>
                </tr>
              );
            } else {
              return (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "#e8e8e8",
                  }}
                >
                  <td className="oc-table-row center-text">{index + 1}</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-text">-</td>
                  <td className="oc-table-row center-heading">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{ fontSize: "28px" }}
                      color="#272727"
                      onClick={() => openModal(index + 1, false)}
                    />
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      {modalIsOpen && (
        <OCModalComponent
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          currentLot={currentLot}
          unitNo={unitNo}
          setUnitNo={setUnitNo}
          ownerName={ownerName}
          setOwnerName={setOwnerName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          ocRole={ocRole}
          setOcRole={setOcRole}
          ownerOccupied={ownerOccupied}
          setOwnerOccupied={setOwnerOccupied}
          invoiceFrequency={invoiceFrequency}
          setInvoiceFrequency={setInvoiceFrequency}
          strataPlan={strataPlan}
          isEditing={isEditing}
          selectedOCMember={selectedOCMember}
          refreshData={refreshData}
        />
      )}
    </div>
  );
};
export default OCMembers;
