import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import moment from "moment";

Modal.setAppElement("#root");

const PropertyModalComponent = ({
  modalIsOpen,
  closeModal,
  handleSubmit,
  strataPlan,
  serviceName,
  setService,
  serviceProvider,
  setProvider,
  providerEmail,
  setEmail,
  providerPhone,
  setPhone,
  contract,
  setContract,
  contractDate,
  setContractDate,
  isEditing,
  selectedService,
}) => {
  const [selectedServiceName, setSelectedServiceName] = useState("");

  const serviceOptions = [
    { value: "insurance", label: "Insurance" },
    { value: "caretaking", label: "Caretaking" },
    { value: "strataManager", label: "Strata Manager" },
    { value: "wasteManagement", label: "Waste Management" },
    { value: "fireServices", label: "Fire Services" },
    { value: "essentialServices", label: "Essential Services" },
    { value: "lifts", label: "Lifts" },
    { value: "recreationalFacilities", label: "Recreational Facilities" },
    { value: "otherServices", label: "Other Services" },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (modalIsOpen) {
      if (isEditing && selectedService) {
        setProvider(selectedService.service_provider);
        setEmail(selectedService.provider_email);
        setPhone(selectedService.provider_phone_no);
        setContract(selectedService.contract);
        setContractDate(selectedService.contract_renewal_date);
      } else {
        setProvider("");
        setEmail("");
        setPhone("");
        setContract(false);
        setContractDate(null);
      }
    }
  }, [modalIsOpen, isEditing, selectedService]);

  useEffect(() => {
    if (modalIsOpen && isEditing && selectedService) {
      setSelectedServiceName(selectedService.service_name);
    } else {
      setSelectedServiceName("");
    }
  }, [modalIsOpen, isEditing, selectedService]);

  const submitForm = (event) => {
    event.preventDefault();

    const formData = {
      strataPlan,
      serviceName: selectedServiceName,
      serviceProvider,
      providerEmail,
      providerPhone,
      contract,
      contractDate,
    };

    handleSubmit(formData);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Edit Form"
      className="modal-container"
    >
      <div className="modal-content form-container">
        <h6>
          {isEditing
            ? `Editing ${serviceName} Service Details for ${strataPlan}`
            : `Adding a New Service for ${strataPlan}`}
        </h6>
        <form
          onSubmit={submitForm}
          className={`form-container ${isEditing ? "center-form" : ""}`}
        >
          {!isEditing ? (
            <div className="flex-row" style={{ paddingBottom: "20px" }}>
              <label className="label-input">Services</label>
              <Select
                options={serviceOptions}
                name="serviceName"
                className="select-width"
                value={
                  selectedServiceName
                    ? serviceOptions.find(
                        (option) => option.value === selectedServiceName
                      )
                    : ""
                }
                onChange={(option) =>
                  setSelectedServiceName(option ? option.label : "")
                }
                required
              />
            </div>
          ) : null}
          <input
            type="text"
            name="serviceProvider"
            placeholder="Service Provider Name"
            className={`input-height ${isEditing ? "wider-input" : ""}`}
            value={serviceProvider || ""}
            onChange={(e) => setProvider(e.target.value)}
            required
          />
          <input
            type="email"
            name="providerEmail"
            placeholder="Service Provider Email"
            className={`input-height ${isEditing ? "wider-input" : ""}`}
            value={providerEmail}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            name="providerPhone"
            placeholder="Service Provider Phone No"
            className={`input-height ${isEditing ? "wider-input" : ""}`}
            value={providerPhone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <label className="label-input checkbox-label">
            Contract in Place?&nbsp;&nbsp;&nbsp;
            <input
              type="checkbox"
              name="contract"
              className="checkbox-input"
              checked={contract}
              onChange={(e) => {
                setContract(e.target.checked);
                if (!e.target.checked) {
                  setContractDate(null);
                }
              }}
            />
          </label>
          {contract && (
            <input
              type="date"
              name="contractDate"
              placeholder="Contract Date"
              className={`input-height ${isEditing ? "wider-input" : ""}`}
              value={
                contractDate ? moment(contractDate).format("YYYY-MM-DD") : ""
              }
              onChange={(e) =>
                setContractDate(e.target.value === "" ? null : e.target.value)
              }
              required
            />
          )}
          <button
            type="submit"
            className={`submit-button ${isEditing ? "wider-input" : ""}`}
          >
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default PropertyModalComponent;
