import React from "react";
import stratabossfavicon from "../../assets/Logo/favicon.png";

const Footer = () => (
  <footer className="app-footer">
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className="footer-image"
          src={stratabossfavicon}
          alt="StrataBoss"
          width="20"
          height="22"
        />
        <h4 style={{ marginLeft: "5px" }}>StrataBoss</h4>
      </div>
      <p>Copyright © 2022 StrataBoss</p>
    </div>
    <div />
    <div>
      <a href="/privacy-policy">Website Privacy Policy</a>
      <br />
      <a href="/terms-and-conditions">Terms & Conditions of Use</a>
    </div>
  </footer>
);

export default Footer;
