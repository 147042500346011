import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import "./hero.css";

const Hero = () => (
  <div className="text-center hero-container">
    <FontAwesomeIcon icon={faBuilding} size="10x" />
    <h1 className="mb-4 pt-5">Welcome to StrataBoss</h1>
    <p className="lead">
      Disrupting Strata Management & empowering Owners Corporations to{" "}
      <strong>
        Be your own <a href="https://strataboss.au">StrataBoss</a>
      </strong>
    </p>
  </div>
);

export default Hero;
