export const deletePropertyServices = async (strataPlan, serviceName) => {
  try {
    const response = await fetch(
      "https://model-seasnail-56.hasura.app/v1/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret":
            "0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G",
        },
        body: JSON.stringify({
          query: `
            mutation DeletePropertyServices($strataPlan: String!, $serviceName: String!) {
              delete_property_services(where: {serv_strata_plan_no: {_eq: $strataPlan}, service_name: {_eq: $serviceName}}) {
                affected_rows
              }
            }
          `,
          variables: { strataPlan, serviceName },
        }),
      }
    );

    const data = await response.json();

    if (data.errors) {
      console.error(data.errors);
      const errorMessage = data.errors
        .map(
          (error) =>
            `Message: ${error.message}, Code: ${error.extensions?.code}`
        )
        .join("\n");
      alert(
        `Failed to delete property service. Error details:\n${errorMessage}`
      );
      return null;
    } else if (data.data.delete_property_services.affected_rows > 0) {
      alert(
        `Successfully deleted property service ${serviceName} for ${strataPlan}`
      );
      return data.data.delete_property_services.affected_rows;
    } else {
      alert(
        `No property service was deleted. Please check the provided details.`
      );
      return 0;
    }
  } catch (error) {
    console.error("Error in deletePropertyServices:", error);
    alert(
      `An error occurred while deleting the property service. Please try again.`
    );
    return null;
  }
};
