export const fetchPropertyDetails = async (email) =>{
    try {
      const response = await fetch('https://model-seasnail-56.hasura.app/v1/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-hasura-admin-secret': '0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G',
        },
        body: JSON.stringify({
          query: `
            query FetchPropertyDetails($email: String!) {
              property_details(where: {user_email: {_eq: $email}}) {
                strata_plan_no
                address
                building_type
                property_services
                annual_budget
                no_of_lots
              }
            }
          `,
          variables: { email },
        }),
      });
  
      const data = await response.json();
  
      if (data.errors) {
        console.error(data.errors);
        const errorMessage = data.errors.map((error) => `Message: ${error.message}, Code: ${error.extensions?.code}`).join('\n');
        throw new Error(`Failed to fetch property details. Error details:\n${errorMessage}`);
      }
  
      return data.data.property_details[0];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }