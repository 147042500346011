import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faFileInvoice, faPaintRoller, faUsers, faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';// import the fontawesome icons
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { fetchPropertyDetails } from '../../services/PropertyDetails/FetchPropertyDetails';

const StrataDashboard = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const [strataData, setStrataData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Clear local storage at the start of a new session
    localStorage.clear();

    // Fetch property details
    fetchPropertyDetails(user.email)
      .then((strataData) => {
        // Store the fetched property details in local storage
        localStorage.setItem('strataData', JSON.stringify(strataData));
        setStrataData(strataData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [user.email]);

  const handleTileClick = (route) => {
    history.push(route);
  };

  if (loading) {
    return <div>Loading...</div>; // Render a loading message if data is being fetched
  }

  const { strata_plan_no: strataPlan } = strataData;

  return (
    <div style={{ backgroundColor: '#32aab8', padding: '35px' }}>
      <h2 className="dashboard-heading" style={{ color: '#F7F6F1', textAlign: 'left', marginBottom: '25px' }}>Strata Plan #{strataPlan} Dashboard</h2>
      <div className="top-dashboard-container" style={{ marginBottom: '35px', display: 'flex', justifyContent: 'space-between' }}>
        <h4 style={{ color: 'black', paddingLeft: '25px', paddingTop: '5px' }}>Cash at Bank $673</h4>
        <h4 style={{ color: 'black', paddingRight: '125px', paddingTop: '5px' }}>OC Arrears $0</h4>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '35px' }}>
      <button className="dashboard-tile" onClick={() => handleTileClick('/property-details')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
        <FontAwesomeIcon icon={faBuilding} size="2x" color="#32aab8" />
        <h4 style={{ color: 'black', paddingTop: '10px' }} >Property Details</h4> 
      </button>
      <button className="dashboard-tile" onClick={() => handleTileClick('/generate-invoices')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
      <div className="dashboard-warning">
    <FontAwesomeIcon icon={faExclamationCircle} />
    <span>Attention: Q3 Invoices Due</span>
  </div>
        <FontAwesomeIcon icon={faFileInvoice} size="2x" color="#32aab8" />
        <h4 style={{ color: 'black', paddingTop: '10px' }} >Generate Invoices</h4>
      </button>
      <button className="dashboard-tile" onClick={() => handleTileClick('/oc-members')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
  <div className="dashboard-alert">
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <span>Warning: Review Recommended</span>
  </div>
  <FontAwesomeIcon icon={faUsers} size="2x" color="#32aab8" />
  <h4 style={{ color: 'black', paddingTop: '10px' }}>OC Members</h4>
</button>
      <button className="dashboard-tile" onClick={() => handleTileClick('/property-services')}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
        <FontAwesomeIcon icon={faPaintRoller} size="2x" color="#32aab8" />
        <h4 style={{ color: 'black', paddingTop: '10px' }} >Property Services</h4>
      </button>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(StrataDashboard, {
  onRedirecting: () => <Loading />,
});