const fetchPropertyServices = async (strataPlanNo) => {
  try {
    const response = await fetch(
      "https://model-seasnail-56.hasura.app/v1/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret":
            "0NQxxnVCzWSNpCars6Bp5Jtlfr04A27EruT9EVRpg0iOfQkpUtXG6Fg5xCPt045G",
        },
        body: JSON.stringify({
          query: `
    query FetchPropertyServices($strataPlanNo: String!) {
      property_services(where: {serv_strata_plan_no: {_eq: $strataPlanNo}}) {
        id
        service_name
        service_provider
        provider_email
        provider_phone_no
        contract
        contract_renewal_date
      }
    }
  `,
          variables: {
            strataPlanNo,
          },
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);

    if (
      !data ||
      !data.data ||
      !data.data.property_services ||
      data.data.property_services.length === 0
    ) {
      return null;
    }

    return data.data.property_services;
  } catch (error) {
    console.error("Error fetching property services:", error);
    return null;
  }
};

export { fetchPropertyServices };
